<template>
  <div class="dashboard-projects pt-5">
    <section class="projects-overview row">
      <div class="col-sm-4 col-xl-3">
        <button class="projects-overview-item total-projects">
          <span class="mr-3">{{ this.getUserProjects.length }}</span> Total
          Projects
        </button>
      </div>
      <div class="col-sm-4 col-xl-3">
        <button class="projects-overview-item public-projects">
          <span class="mr-3">0</span> Public Projects
        </button>
      </div>
      <div class="col-sm-4 col-xl-3">
        <button class="projects-overview-item shared-projects">
          <span class="mr-3">0</span> Shared Projects
        </button>
      </div>
    </section>
    <section class="projects-table row">
      <div class="col-sm-12">
        <grid
          :cols="cols"
          :rows="projectData"
          :pagination="true"
          :search="true"
        >
        </grid>
      </div>
    </section>
  </div>
</template>

<script>
import { Grid } from 'gridjs-vue'
import { mapGetters } from 'vuex'
import { GET_USER_PROJECTS, USER_JOBS } from '@/store/actions.type'

export default {
  name: 'DashboardProjects',
  components: {
    Grid,
  },
  data() {
    return {
      cols: [
        {
          name: 'Id',
          formatter: (cell) => {
            return this.$gridjs.h('img', {
              className: 'load-project-btn',
              src: require('@/assets/images/icons/project-play.svg'),
              onClick: () => {
                this.$router.push({
                  name: 'composer',
                  params: { projectId: cell },
                })
              },
            })
          },
        },
        'Name',
        'Visibility',
        {
          name: 'Owner',
          formatter: (cell) => {
            return this.$gridjs.html(`
              <img class="grid-td user-profile-picture mr-2"
                v-on:click.native='this.onRowClicked'
                src="${cell.avatar}"
                alt="Profile picture"
                @click="this.onRowClicked"
              />
              ${cell.firstName}`)
          },
        },
        'Last Updated',
        {
          name: 'Details',
          formatter: (cell) => {
            return this.$gridjs.h(
              'button',
              {
                className: 'project-details-btn btn-primary',
                onClick: () =>
                  this.$router.push({
                    name: 'dashboard-projectDetails',
                    params: { projectId: cell },
                  }),
              },
              'Details'
            )
          },
        },
      ],
    }
  },
  mounted() {
    document.title = 'My Projects | Piquasso'
    this.$store.dispatch(GET_USER_PROJECTS)
    this.$store.dispatch(USER_JOBS)
  },
  methods: {
    onRowClicked() {
      console.log('Clicked')
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'getUserProjects']),
    projectData() {
      return this.getUserProjects.map((project) => {
        const owner = project.collaborators.filter(
          (collaborator) =>
            collaborator.ProjectPermission.permissionType === 'Owner'
        )[0]
        return [
          project.id,
          project.name,
          'Private',
          owner,
          this.$utilFunc.convertTime(project.updatedAt),
          project.id,
        ]
      })
    },
  },
}
</script>

<style>
.projects-overview {
  margin-bottom: 5rem;
}

.projects-overview-item {
  color: var(--pq-font-color-shade2);
  font-size: 1.25rem;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  border-left: 2px solid #864952;
  padding-left: 1rem;
}

.projects-overview-item span {
  font-size: 2.5rem;
  color: var(--pq-font-color-shade1);
}

.total-projects {
  border-left: 2px solid #864952;
}

.public-projects {
  border-left: 2px solid #2e4f5a;
}

.shared-projects {
  border-left: 2px solid #3a4569;
}

.user-profile-picture {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
}

/* Medium devices (tablets, less than 1200px) */
@media (max-width: 1199.98px) {
  .dashboard-projects {
    padding-top: 100px !important;
  }
  .projects-overview {
    margin-bottom: 5rem;
  }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .projects-overview > div {
    display: flex;
    justify-content: flex-start;
  }
  .projects-overview-item {
    width: 20rem;
    font-size: 1.15rem;
    margin-bottom: 1rem;
  }

  .projects-overview-item span {
    width: 3.5rem;
  }
}
</style>
